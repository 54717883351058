import React, { useRef, useMemo, useEffect, useCallback } from 'react';
import {
  shape,
  string,
  arrayOf,
  oneOfType,
  bool,
  number,
  node,
  object,
} from 'prop-types';
import useResizeObserver from 'use-resize-observer';
import { useRouter } from '@nintendo-of-america/next';
import {
  useLocalizer,
  deserializeHash,
  useFeature,
} from '@nintendo-of-america/react-hooks';
import { Link } from '@nintendo-of-america/next';
import {
  useSearch,
  useSortBy,
  useTracking,
  SelectWithLabel,
  MobileOnly,
  DesktopOnly,
  SORT_BY_TYPE,
} from '@nintendo-of-america/search';
import {
  Button,
  Spacer,
  Breadcrumbs,
  FilterIcon,
  PlusIcon,
  Heading,
  FullBleedPageHero,
  ScreenReaderOnly,
} from '@nintendo-of-america/component-library';
import {
  useRestoreScroll,
  useCustomFilterSet,
  useCachedSearchHits,
  useIsClient,
  FILTER_DIVIDER,
  OPERATORS,
} from '@local/lib/hooks';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import { StoreProductTile } from '@shared/ui';
import { kebabCase, scrollIntoViewIfNeeded } from '@shared/util';
import {
  Farm,
  Section,
  BreadcrumbContainer,
  StoreFooterCTAs,
  Constrained,
  StoryModule,
  FilterButton,
  FiltersGridHeader,
  FiltersGridHeaderWrapper,
  FilteredPageResults,
  FilterResultsGrid,
  LoadMoreSection,
  Hr,
  SearchTrackingProductTile,
} from '@local/components';
import { FilterSectionPropType } from '@local/lib/proptypes';
import { QUERY_PARAMS } from '@shared/util/constants';
import { combineActiveFilters } from '@local/lib/helpers';
import PromoGridItem from './PromoGridItem';
import PLPAttributeFilterSet from './PLPAttributeFilterSet';
import { DRAWER_DESKTOP_HEIGHT } from './ActiveCollectionSticky';
import mergePromosWithProducts from './mergePromosWithProducts';
import useStickyCollectionNav from './useStickyCollectionNav';
import { StoreWrap } from '@local/components/pages/Store';
import * as PLPTemplates from '@local/components/pages/templates';
import { COLLECTION_NAV_SCROLL_TO_OFFSET } from './constants';
import * as S from './PLP.styles';

const CACHE_KEY = 'nintendo.plp.cache';
const DEFAULT_CMS_PAGE = 0;
const SCROLL_TO_ELEMENT_ID = 'scroll-top-of-grid';

function PLPTemplate({
  page,
  pageIcon,
  showRating,
  gridHeaderIsOpen,
  filterSections,
  breadcrumbLinks,
  analytics,
  cachePrefix,
  fullBleedHero,
  cmsProductPageSize,
  seeAllCtaLabel,
  scrollToProductsRef,
  disableDefaultMarketingPageHeading,
  enableStickyCollectionNav,
  hiddenFilters,
}) {
  const {
    pageHeader,
    ctaCollection,
    merchandisedGrid,
    merchandisedGridHeader,
    merchandisedGridRemainingProductsLabel,
    isPromoInFirstPosition,
    marketingPage,
    productsCategoryUid,
  } = page;
  const {
    state: {
      isSearching,
      availableRefinements,
      rawSearchHits,
      hasMore,
      sortBy,
      sortByOptions,
      activeIndexName,
      activeFilters,
      totalHits = 0,
    },
    actions: {
      loadMore,
      refine: baseRefine,
      applySortBy,
      applyCustomParams,
      clearFilters,
      removeFilter,
    },
  } = useSearch();
  const isClient = useIsClient();

  const MarketingPageTemplate = marketingPage
    ? PLPTemplates[marketingPage?.templateName ?? 'plmpStandard']
    : null;

  const MarketingPageHeading = disableDefaultMarketingPageHeading
    ? React.Fragment
    : Heading.NewLevel;

  const router = useRouter();
  const localizer = useLocalizer();
  const { trackLoadMoreProducts } = useTracking();

  const { text } = localizer;
  const { asPath } = router;

  const featureFlag = useFeature('wdev-1631-horizontal-tiles');
  const featureFlagVariant = featureFlag?.variant?.payload?.value;

  const isTabletSmall = useMediaWidth(MEDIA_TYPE.TABLET_SMALL);
  const showHorizontalTiles =
    featureFlagVariant === 'horizontal' && !isTabletSmall;

  const { waitForElementRef } = useRestoreScroll();

  const {
    ref: merchandisedGridHeaderRef,
    height: merchandisedGridHeaderHeight,
  } = useResizeObserver();

  const {
    ref: merchandisedGridMobileHeaderRef,
    height: merchandisedGridMobileHeaderHeight,
  } = useResizeObserver();

  const eventName =
    analytics?.eventPrefix && `${analytics?.eventPrefix} PLP: Product Clicked`;

  const isDesktop = useMediaWidth(MEDIA_TYPE.DESKTOP);

  const isDesktopRef = useRef();
  isDesktopRef.current = isDesktop;

  const params = deserializeHash(asPath);
  const currentCMSPage = parseInt(params.show) || DEFAULT_CMS_PAGE;

  const {
    isFilterModalOpen,
    selectedSortByOption,
    toggleModalFiltersMenu,
    handleSortByChange: baseHandleSortByChange,
  } = useSortBy({
    sortBy,
    sortByOptions,
    applySortBy,
    analytics: { pageName: analytics.pageName },
  });

  const flatProductsWithPromo = useMemo(
    () => (merchandisedGrid || []).flat(),
    [merchandisedGrid]
  );

  const products = useMemo(
    () =>
      // If we're not on the DEFAULT sort, ignore the CMS products
      selectedSortByOption?.value === SORT_BY_TYPE.DEFAULT
        ? flatProductsWithPromo.filter(({ sku }) => Boolean(sku))
        : [],
    [flatProductsWithPromo, selectedSortByOption]
  );

  const productsWithPromos = useMemo(() => {
    if (productsCategoryUid) {
      const promos = flatProductsWithPromo.filter(({ sku }) => !sku);
      return mergePromosWithProducts(promos, products, {
        isPromoInFirstPosition,
      });
    } else {
      return flatProductsWithPromo;
    }
  }, [
    products,
    flatProductsWithPromo,
    isPromoInFirstPosition,
    productsCategoryUid,
  ]);

  const attributes = useMemo(
    () => filterSections.map(({ attribute }) => attribute),
    [filterSections]
  );

  const {
    appliedFilters: cmsAppliedFilters,
    filteredProducts: rawCmsFilteredProducts,
    filteredState: cmsFilteredState,
    refine: cmsRefine,
    refineByQuery: cmsRefineByQuery,
    clearFilters: cmsClearFilters,
  } = useCustomFilterSet(products, attributes, {
    operator: OPERATORS.AND,
    queryToInitializeFilters: deserializeHash(),
    hidden: hiddenFilters,
  });

  const withResetCurrentCMSPage = useCallback(
    (callback) => {
      applyCustomParams({ show: DEFAULT_CMS_PAGE });
      return callback;
    },
    [applyCustomParams]
  );

  const refine = useCallback(
    (...args) => withResetCurrentCMSPage(baseRefine)(...args),
    [withResetCurrentCMSPage, baseRefine]
  );

  const handleCombinedRemoveFilter = useCallback(
    (refinement) => {
      const callback = ({ value, cmsValue }) => {
        if (value) {
          removeFilter(value);
        }

        if (cmsValue) {
          cmsRefine(cmsValue);
        }
      };

      withResetCurrentCMSPage(callback)(refinement);
    },
    [removeFilter, cmsRefine, withResetCurrentCMSPage]
  );

  const handleCombinedClearFilters = useCallback(() => {
    const callback = () => {
      clearFilters();
      cmsClearFilters();
    };

    withResetCurrentCMSPage(callback)();
  }, [clearFilters, cmsClearFilters, withResetCurrentCMSPage]);

  const handleSortByChange = useCallback(
    (...args) => {
      withResetCurrentCMSPage(baseHandleSortByChange)(...args);
    },
    [baseHandleSortByChange, withResetCurrentCMSPage]
  );

  const handleLoadMore = useCallback(() => {
    const { pageName } = analytics || {};
    if (pageName) {
      trackLoadMoreProducts(pageName);
    }

    if (loadMore) {
      loadMore();
    }
  }, [loadMore, trackLoadMoreProducts, analytics]);

  const handleSeeAllClick = useCallback(() => {
    const { pageName } = analytics;
    trackLoadMoreProducts(pageName);

    applyCustomParams({ show: currentCMSPage + 1 });
  }, [analytics, currentCMSPage, trackLoadMoreProducts, applyCustomParams]);

  const localizedSortByOptions = useMemo(
    () =>
      sortByOptions.map(({ value, label }) => ({
        value,
        label: text(label),
      })),
    [sortByOptions, text]
  );

  const scrollToRef = useRef();

  const cachedHits = useCachedSearchHits({
    isSearching,
    cacheKey: CACHE_KEY,
    cachePrefix,
    activeIndexName,
    rawSearchHits,
  });

  const localizedSelectedSortByOption = useMemo(
    () => ({
      value: selectedSortByOption.value,
      label: text(selectedSortByOption.label),
    }),
    [selectedSortByOption, text]
  );

  const cmsActiveFilters = useMemo(
    () =>
      cmsAppliedFilters.map((filter) => ({
        attribute: filter.split(FILTER_DIVIDER)[0],
        label: filter.split(FILTER_DIVIDER)[1],
        value: filter,
      })),
    [cmsAppliedFilters]
  );

  const cmsFilteredProducts = useMemo(() => {
    // CMS Products can sometimes contain duplicates.
    // This is fine when no filters are applied
    // as the promos keep the dups separated in the grid.
    // But once a filter is applied, the promos are
    // removed and so we want to de-dup the CMS products.
    const skus = new Set();
    return rawCmsFilteredProducts.filter((product) => {
      if (!skus.has(product.sku)) {
        skus.add(product.sku);
        return true;
      }
    });
  }, [rawCmsFilteredProducts]);

  const combinedActiveFilters = useMemo(
    () => combineActiveFilters(activeFilters, cmsActiveFilters),
    [activeFilters, cmsActiveFilters]
  );

  const attributeFilterSets = useMemo(
    () =>
      filterSections.map(({ title, attribute, ...options }, index) => {
        const items = availableRefinements?.[attribute] || [];
        return (
          <PLPAttributeFilterSet
            key={attribute}
            title={title && text(title)}
            attribute={attribute}
            startCollapsed={index !== 0}
            showMoreLabel={text('Show more')}
            showLessLabel={text('Show less')}
            collapsible={true}
            limit={5}
            showMore={true}
            refine={refine}
            cmsRefine={cmsRefine}
            items={items}
            cmsItems={cmsFilteredState[attribute]?.items || []}
            analytics={{
              pageName: analytics.pageName,
            }}
            {...options}
          />
        );
      }),
    [
      filterSections,
      text,
      analytics,
      cmsFilteredState,
      cmsRefine,
      refine,
      availableRefinements,
    ]
  );

  const featureFlagAnalytics = useMemo(
    () =>
      // The featureFlag provided at the CLP level
      // will override product tile feature flag
      analytics?.featureFlag
        ? { featureFlag: analytics.featureFlag }
        : {
            featureFlag: featureFlag?.id,
            featureFlagVariant: featureFlagVariant,
          },
    [analytics, featureFlag, featureFlagVariant]
  );

  const displayMerchandisedGridHeader = useMemo(
    () =>
      // Only show the grid header if no sort
      // or filters are applied
      selectedSortByOption?.value === SORT_BY_TYPE.DEFAULT &&
      !combinedActiveFilters.length,
    [combinedActiveFilters, selectedSortByOption]
  );

  const showCMSContent = useMemo(
    () =>
      !activeFilters?.length &&
      !cmsActiveFilters?.length &&
      selectedSortByOption?.value === SORT_BY_TYPE.DEFAULT &&
      productsWithPromos?.length > 0,
    [activeFilters, cmsActiveFilters, selectedSortByOption, productsWithPromos]
  );

  const totalCMSProducts = showCMSContent
    ? productsWithPromos
    : cmsFilteredProducts;

  const cmsPageSize = cmsProductPageSize || totalCMSProducts.length;

  const cmsProducts = useMemo(
    () => totalCMSProducts.slice(0, cmsPageSize * (currentCMSPage + 1)),
    [currentCMSPage, totalCMSProducts, cmsPageSize]
  );

  const hasMoreCMS = cmsProducts.length < totalCMSProducts.length;
  const totalCMSPages = Math.max(
    0,
    Math.ceil(totalCMSProducts.length / cmsPageSize) - 1
  );

  const enableCMSPaging = typeof cmsProductPageSize === 'number';
  const lastCMSProductCount = cmsProducts.length - cmsPageSize * currentCMSPage;

  const showCachedHits =
    isClient &&
    cachedHits.length > 0 &&
    ((!enableCMSPaging && !showCMSContent) ||
      currentCMSPage > totalCMSPages ||
      !cmsProducts?.length ||
      selectedSortByOption?.value !== SORT_BY_TYPE.DEFAULT ||
      // If the last page of CMS products is less
      // than half the CMS paging size, then we should
      // automatically load the first page of cached
      // hits (i.e Algolia products)
      lastCMSProductCount < cmsPageSize / 2);

  const renderedLoadMoreProducts = useMemo(() => {
    // Wait till on client to render load more buttons
    if (!isClient) {
      return null;
    }

    if (hasMoreCMS) {
      return (
        <S.ButtonRow>
          <Button
            variant="tertiary"
            icon={PlusIcon}
            onClick={handleSeeAllClick}
          >
            {text(seeAllCtaLabel)}
          </Button>
        </S.ButtonRow>
      );
    }

    return showCachedHits ? (
      <LoadMoreSection
        isLoading={isSearching}
        hasMore={hasMore}
        onLoadMoreClick={handleLoadMore}
      />
    ) : cachedHits.length > 0 ? (
      <S.ButtonRow>
        <Button variant="tertiary" icon={PlusIcon} onClick={handleSeeAllClick}>
          {text(seeAllCtaLabel)}
        </Button>
      </S.ButtonRow>
    ) : null;
  }, [
    isClient,
    cachedHits,
    isSearching,
    hasMore,
    hasMoreCMS,
    showCachedHits,
    text,
    seeAllCtaLabel,
    handleLoadMore,
    handleSeeAllClick,
  ]);

  const renderedResults = useMemo(() => {
    // Return cms content grid
    if (showCMSContent) {
      return (
        <>
          <FilterResultsGrid
            ref={waitForElementRef}
            $singleColumnMobile={showHorizontalTiles}
          >
            {cmsProducts.map((item, i) => {
              if (item.sku) {
                return (
                  <StoreProductTile
                    {...featureFlagAnalytics}
                    key={`default-cms-${item.sku}-${i}`}
                    {...item}
                    constrain={false}
                    showRating={
                      showRating ||
                      Boolean(router.query[QUERY_PARAMS.UTM_CODE]) ||
                      false
                    }
                    mobileHorizontal={showHorizontalTiles}
                    analytics={{
                      listDetails: {
                        id: `plp-${kebabCase(analytics.pageName)}`,
                        name: analytics.pageName,
                      },
                    }}
                  />
                );
              } else {
                return (
                  <PromoGridItem
                    key={item.heading + i}
                    cta={item.cta}
                    heading={item.heading}
                    asset={item.asset.primary.assetPath}
                  />
                );
              }
            })}
            {showCachedHits &&
              cachedHits.map(({ objectID, ...hit }) => (
                <SearchTrackingProductTile
                  key={`default-search-${objectID}`}
                  {...hit}
                  showRating={showRating || false}
                  mobileHorizontal={showHorizontalTiles}
                  analytics={{
                    eventName,
                    searchId: hit.searchId,
                    position: hit.resultsPosition,
                    locale: router.locale,
                    pageName: analytics?.pageName,
                    itemListDetails: {
                      id: `plp-${kebabCase(analytics.pageName)}`,
                      name: analytics.pageName,
                    },
                    ...featureFlagAnalytics,
                  }}
                />
              ))}
          </FilterResultsGrid>
          {renderedLoadMoreProducts}
        </>
      );
    }

    // Return search results based on selected filters/sort by
    return (
      <>
        <FilterResultsGrid
          ref={waitForElementRef}
          $singleColumnMobile={showHorizontalTiles}
        >
          {cmsProducts.map((item, i) => (
            <StoreProductTile
              {...featureFlagAnalytics}
              key={`filtered-cms-${item.sku}-${i}`}
              {...item}
              constrain={false}
              mobileHorizontal={showHorizontalTiles}
              analytics={{
                listDetails: {
                  id: `plp-${kebabCase(analytics.pageName)}`,
                  name: analytics.pageName,
                },
              }}
            />
          ))}
          {showCachedHits &&
            cachedHits.map(({ objectID, ...hit }) => (
              <SearchTrackingProductTile
                key={`filtered-search-${objectID}`}
                mobileHorizontal={showHorizontalTiles}
                {...hit}
                analytics={{
                  eventName,
                  searchId: hit.searchId,
                  position: hit.resultsPosition,
                  locale: router.locale,
                  pageName: analytics.pageName,
                  itemListDetails: {
                    id: `plp-${kebabCase(analytics.pageName)}`,
                    name: analytics.pageName,
                  },
                  ...featureFlagAnalytics,
                }}
              />
            ))}
        </FilterResultsGrid>
        {renderedLoadMoreProducts}
      </>
    );
  }, [
    analytics.pageName,
    eventName,
    showCMSContent,
    showRating,
    waitForElementRef,
    cmsProducts,
    cachedHits,
    router.locale,
    router.query,
    featureFlagAnalytics,
    showHorizontalTiles,
    showCachedHits,
    renderedLoadMoreProducts,
  ]);

  const showMerchandisedGridHeader =
    displayMerchandisedGridHeader && merchandisedGridHeader;

  useStickyCollectionNav({
    isDesktop,
    scrollToRef,
    scrollToElementID: SCROLL_TO_ELEMENT_ID,
    enableStickyCollectionNav,
    pillFarm: ctaCollection.list,
  });

  useEffect(() => {
    cmsRefineByQuery(deserializeHash(router.asPath));
  }, [cmsRefineByQuery, router.asPath]);

  useEffect(() => {
    if (isDesktopRef.current && scrollToRef.current) {
      scrollIntoViewIfNeeded(scrollToRef.current, {
        offsetY: enableStickyCollectionNav && COLLECTION_NAV_SCROLL_TO_OFFSET,
      });
    }
  }, [
    enableStickyCollectionNav,
    activeFilters.length,
    cmsActiveFilters.length,
  ]);

  useEffect(() => {
    if (enableStickyCollectionNav) {
      scrollIntoViewIfNeeded(scrollToRef.current, {
        offsetY: COLLECTION_NAV_SCROLL_TO_OFFSET,
        behavior: 'smooth',
        onlyScrollIfAbove: true,
      });
    }
  }, [enableStickyCollectionNav]);

  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs currentPage={router.pathname}>
        {breadcrumbLinks.map((link, i) => (
          <Link key={link.title + i} href={link.href}>
            <a>{text(link.title)}</a>
          </Link>
        ))}
      </Breadcrumbs>
    );
  };

  return (
    <StoreWrap disableScrollRestoration={true}>
      {marketingPage && (
        <MarketingPageHeading>
          <MarketingPageTemplate
            marketingPage={marketingPage}
          ></MarketingPageTemplate>
        </MarketingPageHeading>
      )}
      {breadcrumbLinks && (
        <BreadcrumbContainer>{renderBreadcrumbs()}</BreadcrumbContainer>
      )}
      {pageHeader?.asset?.primary?.assetPath && (
        <>
          {fullBleedHero ? (
            <>
              <FullBleedPageHero
                heading={pageHeader.heading}
                subtitle={pageHeader.description}
                image={{
                  desktop: { assetPath: pageHeader.asset?.primary?.assetPath },
                  mobile: { assetPath: pageHeader.asset?.secondary?.assetPath },
                }}
                pillFarm={ctaCollection.list}
                icon={pageIcon}
              />
              <Hr />
            </>
          ) : (
            <StoryModule
              iconName={pageIcon}
              content={{
                assetPath: pageHeader.asset?.primary?.assetPath,
                assetPathAlt: pageHeader.asset?.alt,
                background: pageHeader.background,
                cta: pageHeader.cta,
                description: pageHeader.description,
                heading: pageHeader.heading,
                modifiers: pageHeader.modifiers,
              }}
            />
          )}
        </>
      )}
      <Heading.NewLevel>
        {!fullBleedHero && ctaCollection?.list.length > 0 && (
          <Section constrained small divider={['bottom']}>
            <Farm center items={ctaCollection.list} />
          </Section>
        )}
        <span
          ref={scrollToRef}
          id="products"
          data-drawer-id={SCROLL_TO_ELEMENT_ID}
        />
        <span ref={scrollToProductsRef} />
        <Spacer size={36} axis="vertical" />
        <Constrained>
          <S.StyledSortContainer $breadcrumbs={!!breadcrumbLinks}>
            <DesktopOnly>{breadcrumbLinks && renderBreadcrumbs()}</DesktopOnly>
            <SelectWithLabel
              label={text('Sort by')}
              onSelect={handleSortByChange}
              options={localizedSortByOptions}
              dropdownValue={isClient ? localizedSelectedSortByOption : null}
            />
            <MobileOnly>
              <FilterButton
                icon={FilterIcon}
                variant="tertiary"
                onClick={toggleModalFiltersMenu}
              >
                {text('Filter')}
              </FilterButton>
            </MobileOnly>
          </S.StyledSortContainer>
        </Constrained>
        <Spacer size={32} axis="vertical" />
        {showMerchandisedGridHeader && (
          <MobileOnly>
            <FiltersGridHeader
              $isOpen={gridHeaderIsOpen}
              $height={merchandisedGridMobileHeaderHeight}
            >
              <Constrained>
                <FiltersGridHeaderWrapper ref={merchandisedGridMobileHeaderRef}>
                  {merchandisedGridHeader}
                </FiltersGridHeaderWrapper>
              </Constrained>
            </FiltersGridHeader>
          </MobileOnly>
        )}
        <Constrained>
          <FilteredPageResults
            enableStickyFilterPanel={true}
            isFilterModalOpen={isFilterModalOpen}
            toggleModalFiltersMenu={toggleModalFiltersMenu}
            attributeFilterSets={attributeFilterSets}
            isDesktop={isDesktop}
            stickyOffset={
              enableStickyCollectionNav && parseInt(DRAWER_DESKTOP_HEIGHT) + 16
            }
            customizeFilters={{
              activeFilters: combinedActiveFilters,
              removeFilter: handleCombinedRemoveFilter,
              clearFilters: handleCombinedClearFilters,
              totalHits: totalHits + cmsFilteredProducts.length,
            }}
          >
            {showMerchandisedGridHeader ? (
              <>
                <DesktopOnly>
                  <FiltersGridHeader
                    $isOpen={gridHeaderIsOpen}
                    $height={merchandisedGridHeaderHeight}
                  >
                    <FiltersGridHeaderWrapper ref={merchandisedGridHeaderRef}>
                      {merchandisedGridHeader}
                    </FiltersGridHeaderWrapper>
                  </FiltersGridHeader>
                </DesktopOnly>
                {!!merchandisedGridRemainingProductsLabel && (
                  <>
                    <ScreenReaderOnly>
                      <Heading>
                        {text(merchandisedGridRemainingProductsLabel)}
                      </Heading>
                    </ScreenReaderOnly>
                    {renderedResults}
                  </>
                )}
              </>
            ) : (
              renderedResults
            )}
          </FilteredPageResults>
        </Constrained>
        <StoreFooterCTAs />
      </Heading.NewLevel>
    </StoreWrap>
  );
}

PLPTemplate.defaultProps = {
  filterCMSProducts: true,
  seeAllCtaLabel: 'See all',
};

PLPTemplate.propTypes = {
  page: shape({
    pageHeader: shape(StoryModule.propTypes),
    ctaCollection: shape({
      list: arrayOf(
        shape({
          label: string,
          url: string,
        })
      ),
    }),
    merchandisedGrid: arrayOf(
      oneOfType([
        arrayOf(shape(StoreProductTile.propTypes)),
        shape({
          asset: shape({ primary: shape({ assetPath: string }) }),
          cta: shape({ url: string, title: string }),
          heading: string,
        }),
      ])
    ),
    merchandisedGridHeader: node,
    merchandisedGridRemainingProductsLabel: string,
    isPromoInFirstPosition: bool,
    productsCategoryUid: string,
  }),
  filterSections: arrayOf(FilterSectionPropType).isRequired,
  pageIcon: string,
  showRating: bool,
  gridHeaderIsOpen: bool,
  cachePrefix: string.isRequired,
  analytics: shape({
    eventPrefix: string.isRequired,
    pageName: string.isRequired,
    featureFlag: string,
  }),
  fullBleedHero: bool,
  cmsProductPageSize: number,
  seeAllCtaLabel: string,
  scrollToProductsRef: object,
  disableDefaultMarketingPageHeading: bool,
  enableStickyCollectionNav: bool,
  hiddenFilters: arrayOf(string),
};

export default PLPTemplate;
